import {
    isRequestPayload, isResponsePayload,
    RequestPayload, ResponseCallbackStore, ResponsePayload, ResponsePayloadError, ResponsePayloadResult, ResultCallbackType
} from './message';



abstract class HostMessageChannel {

    responseCallbackStore: ResponseCallbackStore;

    constructor() {
        this.responseCallbackStore = new ResponseCallbackStore();
    }

    messageHandler(event: MessageEvent<any>): void {
        if(event.source) {
            const iframe = this.findIframeAndVerifyOrigin(event.source, event.origin);
            if (iframe) {
                event.stopPropagation();
                const message = event.data;
                if (isResponsePayload(message)) {
                    this.responseCallbackStore.invokeCallbackForResponse(message as ResponsePayload);
                } else if (isRequestPayload(message)) {
                    this.handlePluginRequest(message as RequestPayload, iframe);
                } else {
                    throw Error("Unrecognized message " + message);
                }
            }
        }
    }


    sendRequest(iframe: HTMLIFrameElement,
                                method: string,
                                params: Record<string, any>,
                                callback: ResultCallbackType | null) {
        let request: RequestPayload;
        if (callback) {
            const id = this.responseCallbackStore.storeCallback(callback);
            request = {method, params, id};
        } else {
            request = {method, params};
        }

        this.postMessageToIFrame(iframe, request);
    }

    sendResponse(iframe: HTMLIFrameElement,
                                id: string,
                                error: any,
                                result: any) {
        if (error) {
            const message: ResponsePayloadError = {error, id};
            this.postMessageToIFrame(iframe, message);
        } else {
            const message: ResponsePayloadResult = {result, id};
            this.postMessageToIFrame(iframe, message);
        }
    }
  
    startListening() {
        window.addEventListener("message", (event) => this.messageHandler(event));
    }


    abstract postMessageToIFrame(iframe: HTMLIFrameElement, message: any): void;
    abstract findIframeAndVerifyOrigin(source: MessageEventSource, origin: string): HTMLIFrameElement | null;
    abstract handlePluginRequest(request: RequestPayload, iframe: HTMLIFrameElement): void;
}

interface MethodHandlerContext {
    contentId: string;
    isEditorPlugin: boolean;
}
type MethodHandler = (params: any, context: MethodHandlerContext, callback: ResultCallbackType) => void;


class PluginHost extends HostMessageChannel {

    handlers = new Map<string, MethodHandler>();

    static createIFrames(): void {
        // create an iframe inside each div.content-plugin
        const pluginDivs = document.querySelectorAll("div.content-plugin");
        for (let i = 0; i < pluginDivs.length; i++) {
            const pd = pluginDivs[i];
            if (pd instanceof HTMLDivElement && pd.dataset && pd.dataset.contentPluginUrl !== undefined) {
    
                const loadingAnimation = document.createElement("div");
                loadingAnimation.classList.add("loading");
                pd.appendChild(loadingAnimation);  // Shows animation until iframe is unhidden (done with CSS)

                const iframe = document.createElement("iframe");
                iframe.classList.add("hidden");
    
                // add iframe
                pd.appendChild(iframe)
                iframe.setAttribute("src", pd.dataset.contentPluginUrl);
            }
        }
    }

    postMessageToIFrame(iframe: HTMLIFrameElement, message: any): void {
        const parentDiv = iframe.parentElement;
        if (!parentDiv)
            throw Error("parentDiv not found");
    
        const url = new URL(parentDiv.dataset?.contentPluginUrl as string);
        const origin = url.origin;
        iframe.contentWindow?.postMessage(message, origin);
    }

    findIframeAndVerifyOrigin(source: MessageEventSource, origin: string): HTMLIFrameElement | null {
        const iframes = document.querySelectorAll("div.content-plugin iframe");
        for (let i = 0; i < iframes.length; i++) {
            const iframe = iframes[i] as HTMLIFrameElement;
            if (source === iframe.contentWindow) {
                const parentDiv = iframe.parentElement as HTMLDivElement;
                const url = new URL(parentDiv.dataset?.contentPluginUrl as string);
                if (url.origin === origin) {
                    return iframe;
                } else {
                    throw Error("origin mismatch");
                }
            }
        }
        return null;
    }

    handleChangeHeight(request: RequestPayload, pluginIframe: HTMLIFrameElement) {
        const container = pluginIframe.parentElement;
        if (container) {    // This should never fail
            container.style.height = "" + request.params.height + "px";
        }
    }
    
    handleUnhide(request: RequestPayload, pluginIframe: HTMLIFrameElement) {
        pluginIframe.classList.remove("hidden");
    }

    isEditorPlugin(pluginIframe: HTMLIFrameElement): boolean {

        // TODO: Is it worth checking this for e.g. GetEditorJWT? Or remove the attribute? Or check externally? Or use contentId?

        const parentDiv = pluginIframe.parentElement;
        if (!parentDiv) {
            throw Error("parentDiv not found");
        }
        return parentDiv.classList.contains("content-plugin-editor");
    }
    
    handleGetPluginArguments(request: RequestPayload, pluginIframe: HTMLIFrameElement) {
        if (!request.id) {
            console.log("handleGetPluginArguments request.id not set");
            return;
        }
        // Read config from contaning div's data-content-plugin-arguments attribute
        const configJsonUrlEncoded = pluginIframe.parentElement?.dataset.contentPluginArguments;
        if (!configJsonUrlEncoded) {
            console.log("contentPluginArguments is not set");
            return;
        }
        const pluginArgs = JSON.parse(decodeURIComponent(configJsonUrlEncoded));
        //const arguments = {configuration: {}, snapshot: {}, viewMode: "INTERACTIVE"};
        this.sendResponse(pluginIframe, request.id, null, pluginArgs);
    }
    
    handlePluginRequest(request: RequestPayload, iframe: HTMLIFrameElement) {

        if (this.handlers.has(request.method)) {
            // Read data-irp-content-id from containing div
            const parentDiv = iframe.parentElement;
            const contentId = parentDiv?.dataset?.contentPluginContentId as string;

            if (!contentId) {
                throw Error("contentPluginContentId not found");
            }

            const handler = this.handlers.get(request.method);
            if (handler) {
                const context = {contentId, isEditorPlugin: this.isEditorPlugin(iframe)}
                handler(request.params, context, (err, result) => {
                    if (request.id) {
                        this.sendResponse(iframe, request.id, err, result);
                    }
                });
            }

        } else {
            // built-ins
            switch(request.method) {
                case "changeHeight":
                    this.handleChangeHeight(request, iframe);
                    break;
                case "ready":
                    this.handleUnhide(request, iframe);
                    break;
                case "getPluginArguments":
                    this.handleGetPluginArguments(request, iframe);
                    break;
                default:
                    console.warn("Unhandled method: " + request.method);
            }
        }

    }

    registerHandler(methodName: string, handler: MethodHandler) {
        this.handlers.set(methodName, handler);
    }
}



export function initializePluginHost(): PluginHost {
    // get communication ready before creating iframes (to ensure we don't miss messages)
    const host = new PluginHost();
    host.startListening();

    PluginHost.createIFrames();
    return host;
}


