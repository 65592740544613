import { v4 as uuid } from 'uuid';

export interface RequestPayload {
    method: string,
    params: Record<string, any>,
    id?: string
}

export interface ResponsePayloadResult {
    result: any,
    id: string
}

export interface ResponsePayloadError {
    error: any,
    id: string
}

export type ResponsePayload = ResponsePayloadResult | ResponsePayloadError;

export function isRequestPayload(msg: any): boolean {
    if (typeof msg !== "object") return false;
    if (msg.method === undefined || msg.params === undefined) return false;
    if (msg.id !== undefined && typeof msg.id !== "string") return false;
    return true;
}

export function isResponsePayload(msg: any): boolean {
    if (typeof msg !== "object") return false;
    if (msg.id === undefined || typeof msg.id !== "string") return false;
    if (msg.result !== undefined && msg.error === undefined) return true;
    if (msg.result === undefined && msg.error !== undefined) return true;
    return false;
}


export type ResultCallbackType = (err: any, result: any) => void;
export type RequestHandler = (params: Record<string, any>, cb: ResultCallbackType | null) => void;


export class ResponseCallbackStore {
    responseCallbackMap = new Map<string, ResultCallbackType>();

    storeCallback(callback: ResultCallbackType): string {
        const id = uuid(); 
        this.responseCallbackMap.set(id, callback);
        return id;
    }

    invokeCallbackForResponse(response: ResponsePayload) {
        const callback = this.responseCallbackMap.get(response.id);
        if (callback) {
            if ((response as ResponsePayloadError).error) {
                callback((response as ResponsePayloadError).error, null);
            } else {
                callback(null, (response as ResponsePayloadResult).result);
            }
            this.responseCallbackMap.delete(response.id);
        } else {
            throw Error("Error: responseId callback not found " + response.id);
        }
    }
}
