import {v4 as uuid} from "uuid";
import {
    isRequestPayload,
    isResponsePayload,
    RequestHandler,
    RequestPayload,
    ResponseCallbackStore,
    ResponsePayload, ResultCallbackType,
    ResponsePayloadError, ResponsePayloadResult
} from "./message";

class ClientMessageChannel {

    responseCallbackStore = new ResponseCallbackStore();
    requestHandlers = new Map<string, RequestHandler>();

    private constructor(private targetWindow: Window, private targetOrigin: string) {
    }

    registerRequestHandler(method: string, handler: RequestHandler) {
        this.requestHandlers.set(method, handler);
    }

    handleRequestMessage(request: RequestPayload) {
        console.log("handleRequestMessage");
        const handler = this.requestHandlers.get(request.method);
        if (!handler) {
            throw Error("Handler not found for " + request.method);
        }

        if (request.id) {
            const sendResultIfHasIdCallback = (error: any, result: any) => {
                if (error !== null && error !== undefined) {
                    window.parent.postMessage({error: error, id: request.id}, this.targetOrigin);
                } else {
                    window.parent.postMessage({result: result, id: request.id}, this.targetOrigin);
                }
            }
            handler(request.params, sendResultIfHasIdCallback);
        } else {
            // notification (no result or error returned)
            handler(request.params, null);
        }
    }


    messageListener(event: MessageEvent<any>) {

        // TODO: is "*" safe here? i.e. should only pages on specific domains be able to host this iframe? not sure
        if (event.source === this.targetWindow &&
            (this.targetOrigin === "*" || event.origin === this.targetOrigin)) {

            if (isResponsePayload(event.data)) {
                this.responseCallbackStore.invokeCallbackForResponse(event.data as ResponsePayload);
            } else if (isRequestPayload(event.data)) {
                this.handleRequestMessage(event.data as RequestPayload);
            } else {
                throw Error("Unrecognized message " + event.data);
            }
            event.stopPropagation();
        }
    }

    sendRequest(method: string, params: Record<string, any>, callback: ResultCallbackType | null) {
        let request: RequestPayload;
        if (callback) {
            const id = this.responseCallbackStore.storeCallback(callback);
            request = {method, params, id};
        } else {
            request = {method, params};
        }
        window.parent.postMessage(request, this.targetOrigin);
    }

    static createAndStartListening(sourceWindow: Window, targetWindow: Window, targetOrigin: string): ClientMessageChannel {
        const channel = new ClientMessageChannel(targetWindow, targetOrigin);
        sourceWindow.addEventListener("message", (event) => channel.messageListener(event));
        return channel;
    }

}


export interface PluginArguments {
    configuration: Record<any, any>;
    viewMode: "INTERACTIVE" | "HEADLESS"
    editorConfiguration?: Record<any, any>;
    snapshot?: Record<any, any>;
};

type GetPluginArgumentsCallback = (c:PluginArguments)=>(void);

type RequestSnapshotCallback = (error: any, snapshot: Record<any, any> | null) => void;
type RequestSnapshotHandler = (saveSnapshotCallback: RequestSnapshotCallback) => void;

interface EditorSaveData {
    // fields from PluginInstanceDefinition except pluginType (which can't be editted)
    height?: number,
    configuration?: any,
    jwtClaims?: Record<string, any>,
    searchTerms?: string[]
}

type RequestEditorSaveCallback = (error: any, value: EditorSaveData | null) => void;
type RequestEditorSaveHandler = (setEditorValue: RequestEditorSaveCallback) => void;


type CallbackType<R> = (error: any, result: R | null) => (void);

class PluginClient {

    channel: ClientMessageChannel;
    arguments: PluginArguments | null = null;

    constructor(private autoResize: boolean) {
        this.channel = ClientMessageChannel.createAndStartListening(window, window.parent, "*");
    
        /*
        if (autoResize) {
            // TODO: figure out why this doesn't work
            let previousHeight = -1;
            let sendMessageOnHeightChange = ()=> {
                if (previousHeight !== document.body.offsetHeight) {
                    channel?.sendRequest("__changeHeight", {height: document.body.offsetHeight}, null);
                }
                previousHeight = document.body.offsetHeight;
            }
            sendMessageOnHeightChange();
            setInterval(sendMessageOnHeightChange, 250);
        }
        */
    }

    getPluginArguments(callback: CallbackType<PluginArguments>): void {
        if (this.arguments) {
            callback(null, this.arguments);
        } else {
            this.channel.sendRequest("getPluginArguments", {}, (err, result) => {
                if (err) {
                    console.log("getPluginArguments returned error");
                    callback(err, null);
                } else {
                    this.arguments = result as PluginArguments;
                    callback(null, this.arguments)
                }
            });
        }
    }

    getJwt(callback: CallbackType<string>): void {
        this.channel.sendRequest("getJwt", {},
            (err, result) => {
                if (err) {
                    callback(err, null);
                } else {
                    callback(null, result);
                }
        });
    }

    getEditorJwt(callback: CallbackType<string>): void {
        this.channel.sendRequest("getEditorJwt", {},
            (err, result) => {
                if (err) {
                    callback(err, null);
                } else {
                    callback(null, result);
                }
        });
    }

    setHeight(pixels: number) {
        this.channel.sendRequest("changeHeight", {height: pixels}, null);
    }
    
    ready() {
        this.channel.sendRequest("ready", {}, null);
    }

    editorValueChanged(valid: boolean) {
        this.channel.sendRequest("editorValueChanged", {valid}, null);
    }
   
    onRequestEditorSave(handler: RequestEditorSaveHandler) {
        this.channel.registerRequestHandler("requestEditorSave", (paramsIgnored, callback) => {
            handler((error, editorValue) => {
                if (callback) {
                    callback(error, editorValue);
                }
            });
        });
    }

    onRequestSnapshot(handler: RequestSnapshotHandler) {
        this.channel.registerRequestHandler("requestSnapshot", (paramsIgnored, callback) => {
            handler((error, snapshot) => {
                if (callback) {
                    callback(error, snapshot);
                }
            });
        });
    }

    callApi(method: string, parameters: Record<string, any>, callback: (error: any, result: any)=>(void)) {
        this.channel.sendRequest(method, parameters,
            (err, result) => {
                if (err) {
                    callback(err, null);
                } else {
                    callback(null, result);
                }
        });
    }
    

}


export function initializePlugin(autoResize: boolean): PluginClient {
    let client = new PluginClient(autoResize);
    return client;
}
